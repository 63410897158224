import React, { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import intro from '../assets/audio/intro.ogg';
import '../assets/scss/components/intro.scss';
import '../assets/scss/utils.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh, faVolumeLow, faVolumeMute, faVolumeOff, faVolumeXmark } from '@fortawesome/free-solid-svg-icons';

type Props = {
    setIntroFinished: (state: boolean) => void;
    setIntroStarted: (state: boolean) => void;
    introFinished: boolean;
    introStarted: boolean;
    presentationLoaded: boolean;
    children?: ReactNode | undefined;
};

const Intro = ({ children, introFinished, setIntroFinished, setIntroStarted, introStarted, presentationLoaded }: Props) =>
{
    const [visibleLine, setVisibleLine] = useState(-1);
    const [volume, setVolume] = useState(Number.parseInt(localStorage.getItem("intro-volume") ?? '3'));
    const audioRef = useRef<HTMLAudioElement>(null);

    useEffect(() =>
    {
        audioRef.current.volume = volume / 3.0;
        localStorage.setItem("intro-volume", volume.toString());
    }, [volume]);

    const lines = [
        { starts: 8, line: "You are about to explore the scales of geological time, unimaginably vast compared to a single human lifespan." },
        { starts: 19, line: "This presentation was created to try and convey the sheer magnitude and number of deaths that life had to endure." },
        { starts: 30, line: "You will be able to travel 750 million years back into the past, and see an estimation of what Earth’s continents looked like." }
    ];

    const handleTimeUpdate = (e: SyntheticEvent<HTMLAudioElement>) =>
    {
        if (e.currentTarget.currentTime >= 40)
        {
            setVisibleLine(lines.length);
            return;
        }

        for (let i = 0; i < lines.length; i++)
        {
            const line = lines[i];
            if (e.currentTarget.currentTime >= line.starts)
            {
                setVisibleLine(i);
            }
        }
    };

    const handleStart = () =>
    {
        setIntroStarted(true);
        audioRef.current?.play();
    };

    const handleEnd = () =>
    {
        setIntroFinished(true);
    };

    const toggleMute = () =>
    {
        setVolume((volume + 1) % 4);
    };

    const handleSkip = () =>
    {
        audioRef.current?.pause();
        setIntroFinished(true);
    };

    let volumeIcon = faVolumeXmark;
    if (volume === 1)
    {
        volumeIcon = faVolumeOff;
    } else if (volume === 2)
    {
        volumeIcon = faVolumeLow;
    } else if (volume === 3)
    {
        volumeIcon = faVolumeHigh;
    }

    return (
        <>
            {children}
            <div id='intro' className={introFinished ? 'finished' : ''}>
                {lines.map((line, index) =>
                {
                    let lineVisiblity = index <= visibleLine ? 'visible' : '';
                    if (visibleLine >= lines.length)
                    {
                        lineVisiblity = 'ended';
                    }

                    return <div className={`line ${lineVisiblity}`}>{line.line}</div>;
                })}
                <button id='play' className={introStarted ? 'hidden' : ''} onClick={handleStart}>BEGIN</button>
                <div id='mute-button' className='button' onClick={toggleMute}><FontAwesomeIcon icon={volumeIcon}></FontAwesomeIcon></div>
                {presentationLoaded && <div id='skip-button' className='button' onClick={handleSkip}>Skip...</div>}
                <audio muted={volume === 0} ref={audioRef} autoPlay={introStarted} onEnded={handleEnd} onTimeUpdate={handleTimeUpdate} src={intro}></audio>
            </div>
        </>

    );
};

export default Intro;