import { createRoot } from 'react-dom/client';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useControls } from 'leva';
import './assets/scss/index.scss';
import { useMediaQuery } from 'usehooks-ts';
import Intro from './components/intro';

function App ()
{
  const [darkMode, setDarkMode] = useState(true);
  const isMobile = useMediaQuery('(max-width: 80em)');
  const [introFinished, setIntroFinished] = useState(localStorage.getItem('intro-finished') === 'true');
  const [introStarted, setIntroStarted] = useState(false);
  const [presentationLoaded, setPresentationLoaded] = useState(false);
  const Presentation = lazy(() => import('./components/presentation'));
  useEffect(() =>
  {
    setPresentationLoaded(Presentation._payload._status === 1);
  }, [Presentation._payload._status]);

  useEffect(() =>
  {
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', event =>
      {
        setDarkMode(event.matches);
      });
  }, []);

  return <div id={"container"} className={isMobile ? ' mobile-mode' : ''} data-theme={darkMode ? "dark" : ""}>
    {(introFinished || introStarted) && <Suspense fallback={
      <span className="loader"></span>
    }>
      <Presentation darkMode={darkMode} isMobile={isMobile}></Presentation>
    </Suspense>
    }
    {<Intro presentationLoaded={presentationLoaded} introFinished={introFinished} introStarted={introStarted} setIntroFinished={setIntroFinished} setIntroStarted={setIntroStarted} />}
  </div>;
}

const root = createRoot(document.getElementById('root')!);
root.render(<App />);